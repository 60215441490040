<template>
  <section class="treatment-composition">
    <div v-for="product in composition" :key="product.name" class="composition-section__list-item">
      <div class="list-item__wrapper" :class="{ 'list-item__wrapper--deleted': product.isDeleted }">
        <div class="flex--space-between">
          <div>
            <div
              class="list-item__name-label"
              :class="{
                'updated-product-text': product.isUpdated,
                'deleted-product-text': product.isDeleted
              }"
            >
              {{ getNameLabel(product) }}
            </div>

            <div
              v-for="(description, index) in product.descriptions"
              :key="`${description}${index}`"
              class="list-item__description-text"
            >
              {{ getProductDescription(product, description) }}
            </div>
          </div>

          <div class="flex">
            <span v-if="displayMultipliers" class="list-item__multiplier mr20">
              x{{ product.multiplier }}
            </span>
            <span
              class="list-item__price-text"
              :class="{
                'updated-product-text': product.isUpdated,
                'deleted-product-text': product.isDeleted
              }"
            >
              {{ displayedCurrency | currencyFormat }}{{ product.price }}
            </span>
          </div>
        </div>

        <div v-if="product.microneedling" class="flex--space-between">
          <div class="list-item__description-text microneedling-description-text mt5">
            <span class="microneedling-description-text__title">
              {{ $t('dashboard.microneedling.step') }}
            </span>
            {{ getMicroneedlingDescription(product.microneedling) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showTotalPrice"
      class="treatment-composition__total-section composition-total-section"
    >
      <div class="composition-total-section__price-section total-price-section">
        <template v-if="shouldShowAdditionalPaymentInfo">
          <span data-test-id="shipping-label" class="total-price-section__field-label">
            {{ $t('dashboard.label.shipping') }}
          </span>
          <span
            v-if="isFreeShipping"
            data-test-id="shipping-value"
            class="total-price-section__field-value"
          >
            {{ $t('dashboard.label.shippingFree') }}
          </span>
          <span v-else data-test-id="shipping-value" class="total-price-section__field-value">
            {{ displayedCurrency | currencyFormat }}{{ treatmentDeliveryPrice }}</span
          >
        </template>

        <template v-if="shouldShowPromoCodeAmount">
          <span data-test-id="promo-code-label" class="total-price-section__field-label">
            {{ $t('dashboard.label.deduction') }}
          </span>
          <span data-test-id="promo-code-value" class="total-price-section__field-value">
            -{{ displayedCurrency | currencyFormat }}{{ treatmentPromoCodeAmount }}
          </span>
        </template>

        <span
          data-test-id="total-price-label"
          class="total-price-section__field-label total-price-label"
        >
          {{ $t('dashboard.label.totalOnHistory') }}
        </span>
        <span
          data-test-id="total-price-value"
          class="total-price-section__field-value bold"
          :class="{ 'updated-product-text': isTotalPriceUpdated }"
        >
          {{ displayedCurrency | currencyFormat }}{{ totalPrice | formatNumberTwoDecimalPart }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { getIngredientsDescription } from '@/modules/dashboard/utils/create-treatment-utils';

import { types } from '@/modules/dashboard/store/types';
import {
  TREATMENT_COMPOSITION_PRODUCT_TYPE,
  TREATMENT_COMPOSITION_SECTION_KEY,
  SERUM_USAGE_ABBREVIATION,
  COMPLEMENTARY_PRODUCT_SIZE_UNITS,
  PRODUCT_SIZE_LABEL
} from '@/modules/dashboard/api/constants';

export default {
  name: 'TreatmentComposition',
  props: {
    composition: {
      type: Array,
      required: true
    },
    showTotalPrice: {
      type: Boolean,
      default: false
    },
    displayMultipliers: {
      type: Boolean,
      default: false
    },
    treatmentCurrency: {
      type: String,
      default: ''
    },
    treatmentAmount: {
      type: Number,
      default: 0
    },
    treatmentDeliveryPrice: {
      type: Number,
      default: null
    },
    treatmentPromoCodeAmount: {
      type: Number,
      default: null
    },
    treatmentOrderAmount: {
      type: Number,
      default: null
    },
    shouldShowAdditionalPaymentInfo: {
      type: Boolean,
      default: false
    },
    shouldCalculateCompositionTotal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currency: types.getters.GET_CURRENCY
    }),
    displayedCurrency() {
      return this.treatmentCurrency || this.currency;
    },
    totalPrice() {
      const totalPrice =
        this.treatmentAmount && !this.shouldCalculateCompositionTotal
          ? this.treatmentAmount
          : this.composition.reduce((total, { price }) => total + price, 0);

      const shouldShowtreatmentOrderAmountPrice =
        (this.treatmentDeliveryPrice || this.treatmentPromoCodeAmount) &&
        !this.shouldCalculateCompositionTotal;

      if (shouldShowtreatmentOrderAmountPrice) {
        return this.treatmentOrderAmount;
      }

      return totalPrice;
    },
    isTotalPriceUpdated() {
      return this.composition.some(({ isUpdated, isDeleted }) => isUpdated || isDeleted);
    },
    deliveryPrice() {
      return this.treatmentDeliveryPrice ? this.treatmentDeliveryPrice : 'FREE';
    },
    isFreeShipping() {
      return !this.treatmentDeliveryPrice;
    },
    shouldShowPromoCodeAmount() {
      return this.shouldShowAdditionalPaymentInfo && this.treatmentPromoCodeAmount;
    }
  },
  methods: {
    getNameLabel({ name, isSerum }) {
      return isSerum
        ? `${this.$t('dashboard.settings.serumUnit')} /${this.$t(name)}`
        : this.$t(name);
    },
    getProductDescription({ productName }, description) {
      if (description.productType === TREATMENT_COMPOSITION_PRODUCT_TYPE.COMPLEMENTARY_CARE) {
        return this.getComplementaryCareProductDescription(productName, description);
      }

      return this.getSerumProductDescription(description);
    },
    getComplementaryCareProductDescription(productName, { usage, additionalData, size }) {
      const [sectionNameKey] = additionalData;
      const descriptionKey =
        TREATMENT_COMPOSITION_SECTION_KEY[sectionNameKey][productName] ||
        TREATMENT_COMPOSITION_SECTION_KEY[sectionNameKey];
      const description = [this.$t(descriptionKey)];

      const sizeLabel = PRODUCT_SIZE_LABEL[COMPLEMENTARY_PRODUCT_SIZE_UNITS[productName]];

      if (size) {
        description.push(`${size}${this.$t(sizeLabel)}`);
      }

      if (usage) {
        description.push(usage);
      }

      return description.join(', ');
    },
    getSerumProductDescription({ usage, additionalData }) {
      const ingredients = getIngredientsDescription({
        ingredients: additionalData,
        $t: this.$t,
        locale: this.$i18n.locale,
        translationPrefix: 'dashboard'
      });

      return `${usage} (${ingredients})`;
    },
    getMicroneedlingDescription({ usage, volume, needleSize }) {
      const usageAbbreviation = SERUM_USAGE_ABBREVIATION[usage];

      return `(${usageAbbreviation} serum, ${volume}ml, ${needleSize}mm)`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.treatment-composition {
  &__total-section {
    padding-top: 10px;
    border-top: 1px solid $grey-color;
  }
}

.composition-section {
  &__list-item {
    &:not(:first-child) {
      padding-top: 10px;
    }

    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid $border-light-grey-color;
    }
  }
}

.list-item {
  &__wrapper {
    &--deleted {
      opacity: 0.3;
    }
  }

  &__name-label {
    font-weight: 600;
    font-size: 15px;
    color: $text-color;
  }

  &__description-text,
  &__multiplier,
  &__price-text {
    font-size: 12px;
    color: $grey-color;
  }

  &__multiplier,
  &__price-text {
    font-weight: 500;
  }
}

.microneedling-description-text {
  &__title {
    font-weight: 600;
    color: $text-color;
  }
}

.updated-product-text {
  color: $danube;
}

.deleted-product-text {
  text-decoration-line: line-through;
}

.composition-total-section {
  &__price-section {
    font-size: 14px;
    line-height: 17px;
    color: $text-color;

    display: grid;
    grid-template-columns: minmax(auto, 255px) 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 7px;
  }

  .total-price-section {
    &__field-label,
    &__field-value {
      text-align: right;
    }

    &__field-label {
      font-weight: 500;
    }
  }

  .total-price-label {
    color: $active-title-color;
    font-weight: bold;
  }
}

@media (max-width: 767px) {
  .list-item {
    &__name-label {
      font-size: 14px;
    }
  }
}
</style>
