<template>
  <section class="summary-section">
    <div class="summary-section__label">{{ $t(labelKey, [fullPatientName]) }}</div>
    <div class="summary-section__info-container flex--center--center">
      <div class="info-section__price-label">
        {{ currency | currencyFormat }}{{ treatmentPrice | formatNumberTwoDecimalPart }}
      </div>
      <div class="info-section__divider">•</div>
      <div class="info-section__days-label">
        {{ $t('dashboard.label.durationDays', [summaryTreatmentDuration]) }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { types } from '@/modules/dashboard/store/types';

export default {
  name: 'SummaryInfo',
  props: {
    labelKey: {
      type: String,
      required: true
    },
    useActivePromoPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      fullPatientName: types.getters.CURRENT_PATIENT_FULL_NAME,
      currency: types.getters.GET_CURRENCY,
      summaryTreatmentPrice: types.getters.SUMMARY_TREATMENT_TOTAL_PRICE,
      summaryTreatmentPriceWithPromo: types.getters.SUMMARY_TREATMENT_PRICE_WITH_PROMO,
      summaryTreatmentDuration: types.getters.SUMMARY_TREATMENT_TOTAL_DURATION
    }),
    treatmentPrice() {
      return this.useActivePromoPrice
        ? this.summaryTreatmentPriceWithPromo
        : this.summaryTreatmentPrice;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.summary-section {
  width: 100%;

  &__label {
    padding-bottom: 10px;

    border-bottom: 1px solid $separator-grey-color;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: $text-color;
  }

  &__info-container {
    margin-top: 10px;
    font-size: 15px;
  }
}

.info-section {
  &__price-label {
    color: $orange-color;
    margin-right: 8px;
  }

  &__divider {
    color: $grey-color;
  }

  &__days-label {
    color: $text-color;
    margin-left: 8px;
  }
}

@media (max-width: 767px) {
  .summary-section {
    &__label,
    &__info-container {
      font-size: 13px;
    }

    &__label {
      border-bottom: none;
    }

    &__info-container {
      margin-top: 0;
    }
  }
}
</style>
