<template>
  <main class="summary-composition">
    <mobile-header class="summary-composition__header">
      <span slot="back-button" class="custom-icon icon-close mr15" @click="closePage"></span>
      <span slot="header-title">{{ $t('dashboard.label.createTreatmentTab') }}</span>
    </mobile-header>
    <summary-info class="onlyXS mb30" label-key="dashboard.label.newTreatmentFor" />
    <div class="treatment-section m0a">
      <div class="treatment-section__header">
        <div class="treatment-section__header-label inline-block">
          {{ $t('dashboard.summary.treatmentComposition') }}
        </div>
      </div>
      <treatment-composition class="treatment-composition" :composition="treatmentComposition" />
    </div>
    <mobile-fixed-footer class="summary-composition__footer flex--center--center">
      <button
        slot="footer"
        class="rounded-button-orange rounded-button-orange--filled onlyXS"
        @click="onContinueClick"
      >
        {{ $t('dashboard.action.next') }}
      </button>
    </mobile-fixed-footer>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import SummaryInfo from '@/modules/dashboard/components/create-treatment-tab/SummaryInfo';
import TreatmentComposition from '@/modules/dashboard/components/common/TreatmentComposition';
import MobileFixedFooter from '@/modules/dashboard/components/dashboard/common/MobileFixedFooter';

import { types } from '@/modules/dashboard/store/types';

export default {
  name: 'SummaryTreatmentComposition',
  components: {
    MobileHeader,
    SummaryInfo,
    TreatmentComposition,
    MobileFixedFooter
  },
  computed: {
    ...mapGetters({
      getSummaryTreatmentComposition: types.getters.SUMMARY_TREATMENT_COMPOSITION
    }),
    treatmentComposition() {
      return this.getSummaryTreatmentComposition({});
    }
  },
  methods: {
    ...mapActions({
      resetCreateTreatmentSelections: types.actions.RESET_CREATE_TREATMENT_SELECTIONS
    }),
    closePage() {
      if (this.$route.query.summaryRenewTreatment) {
        this.resetCreateTreatmentSelections();
      }

      this.$router.go(-1);
    },
    onContinueClick() {
      this.$router.push({
        name: 'SummaryTreatmentDetails'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.summary-composition {
  &__footer {
    display: none;
  }
}

.treatment-section {
  &__header {
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid $border-light-grey-color;

    &-label {
      margin-bottom: -1px;
      padding-bottom: 10px;

      font-size: 14px;
      font-weight: 600;
      color: $text-color;

      border-bottom: 1px solid $orange-color;
    }
  }
}

@media (max-width: 767px) {
  .summary-composition {
    padding: 60px 0 150px;

    .summary-composition__header {
      font-size: 13px;
      font-weight: 400;
      color: $text-color;
      background-color: $background;
    }

    &__footer {
      display: flex;
      height: 60px;

      background-color: $white;
      border-top: 1px solid $dim-white;
      box-shadow: 0px -6px 7px rgba(148, 166, 176, 0.15);
    }
  }

  .treatment-section {
    width: 100%;
    max-width: 350px;

    &__header {
      margin-bottom: 30px;

      &-label {
        padding-bottom: 15px;
        font-size: 12px;
      }
    }
  }

  .treatment-composition {
    padding: 0 20px;
  }
}
</style>
